import { $$ } from './utils';
const fancy = (selectors) => {
    selectors.forEach(item => {
        $$(`.${item.trigger}`).forEach((trigger) => {
            trigger.on('click', () => {
                $.fancybox.open({
                    src: `#${item.form}`,
                    type: 'inline'
                });
            });
        });
    });
};
export default fancy;
